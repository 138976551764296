import axios from 'axios'
import JwtService from '@/services/jwt.service'

const ApiService = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

export const setAuthHeader = () => {
    const token = JwtService.getToken()
    if (token) {
        ApiService.defaults.headers.common['Authorization'] = token
        //ApiService.defaults.headers.common['Authorization'] = `Token ${JwtService.getToken()}`
    }
}

setAuthHeader()

export default ApiService