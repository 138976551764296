import { io } from 'socket.io-client';

class SocketioService {
    socket;
    constructor() {}

    setupSocketConnection() {
        this.socket = io(process.env.VUE_APP_SOCKETIO_URL);
        //this.socket = io(process.env.VUE_APP_SOCKETIO_URL, {path: '/wsapp/socket.io'});
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect()
        }
    }    
}

export default new SocketioService()